import React, {useContext} from 'react'
import {Table, Spinner} from "reactstrap"
import {ModalContext} from "../../contexts"
import {cryptoIconsMapper, isTwoPrecision, prettyDate, printTransactionType, toTwoPrecision} from "../../helpers/utils"
import {useTranslation} from "react-i18next"

const FullTransactionModal = (data) => {
	const {closeModal} = useContext(ModalContext)
  const { t } = useTranslation()

  const baseAssetCode = data?.selectedTransaction?.base_asset?.currency?.code
  const quoteAssetCode = data?.selectedTransaction?.quote_asset?.currency.code
  const type = data?.selectedTransaction?.type
  const quoteAmount = data?.selectedTransaction?.quote_amount
  const baseAmount = data?.selectedTransaction?.base_amount
  const totalFee = data?.selectedTransaction?.total_fee
  const usdAmount = data?.selectedTransaction?.usd_amount
  const status = data?.selectedTransaction?.status

	return (
		<React.Fragment>
			{data ?
				(
					<div>
						<Table responsive className="table-striped mb-3">
							<tbody>
								<tr className="fw-semibold">
									<td>ID</td>
									<td>#{data.selectedTransaction.id}</td>
								</tr>
								<tr>
									<td>{t("Base Asset")}</td>
										<td>
											<div className="d-flex align-items-center">
												<div className="avatar-xxs me-2">
													<img
														src={cryptoIconsMapper[baseAssetCode]}
														className="img-fluid"
														alt="3"
													/>
												</div>
												<span>{baseAssetCode}</span>
											</div>
										</td>
								</tr>
                {type === 'exchange' ?
                  <>
                    <tr>
                      <td>{t("Buy amount")}</td>
                      <td>
                        {isTwoPrecision(baseAssetCode, baseAmount)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Quote Asset")}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-xxs me-2">
                            <img
                              src={cryptoIconsMapper[quoteAssetCode]}
                              className="img-fluid"
                              alt="3"
                            />
                          </div>
                          <span>{quoteAssetCode}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Sell amount")}</td>
                      <td>
                        {isTwoPrecision(quoteAssetCode, quoteAmount)}
                      </td>
                    </tr>
                  </>
                  :
                  <tr>
                    <td>{t("Amount")}</td>
                    <td>
											{isTwoPrecision(baseAssetCode, baseAmount)}
                    </td>
                  </tr>
                }
                <tr>
                  <td>
                    {t("Type")}
                  </td>
                  <td>
                    {t(`${
                      printTransactionType(data
                      .selectedTransaction.invoice_id && Number(usdAmount) < 0 ? 'invoice' : type).capitalize()}`)}
                  </td>
                </tr>
                <tr>
                  <td>{t("Status")}</td>
                  <td>
                <span className={'badge badge-soft-' + data?.selectedTransaction.status_color}>
                  {status === 'pending' ? (
                    <i className="ri-time-line align-bottom me-1"></i>
                  ) : (status === 'executed' ? (
                    <i className="ri-checkbox-circle-line align-bottom me-1"></i>
                  ) : (status === 'declined' ?
                      <i className="ri-close-circle-line align-bottom me-1"></i> : ''
                  ))}
                  {t(`${(status).capitalize()}`)}
                </span>
                  </td>
                </tr>
                <tr>
                  <td>{t("Fee")}</td>
									<td>
										{data.selectedTransaction.invoice_id ? '0%' : (
											<span>
      										{(data.selectedTransaction.fee_pct * 100).toFixed(1) * 1}%
												{type === 'deduction' ? (
													<span>
          									({isTwoPrecision(baseAssetCode, totalFee)} {baseAssetCode})
        									</span>
												) : (
													type === 'exchange' ? (
														<span>
															&nbsp;({isTwoPrecision(quoteAssetCode, totalFee)} {quoteAssetCode})
														</span>
													) : null
												)}
   										 </span>
										)}
									</td>
                </tr>
                {type === 'exchange' ? (
                  <>
                    <tr>
                      <td>{t("Buy amount in USD")}</td>
                      <td>{toTwoPrecision(usdAmount)}$</td>
                    </tr>
                    <tr>
                      <td>{t("Total sell amount")}</td>
                      <td>
                        {isTwoPrecision(quoteAssetCode,
                          (Number(quoteAmount) + Number(totalFee)))}
                      </td>
                    </tr>
                  </>
                  ) : (
                     <>
                       <tr>
                         <td>{t("USD Amount")}</td>
                         <td>{toTwoPrecision(usdAmount)}$</td>
                       </tr>
                       <tr>
                         <td>{t("Total")}</td>
												 {type === 'deduction' ?
													 <td>
														 {isTwoPrecision(baseAssetCode, (Number(baseAmount) + Number(totalFee)))}
													 </td>
												 :
													 <td>{isTwoPrecision(baseAssetCode ,baseAmount)}</td>
												 }
                       </tr>
                     </>
                  )
                }

                <tr>
                  <td>{t("Date")}</td>
                  <td>{prettyDate(data.selectedTransaction.created_at)}</td>
                </tr>
							</tbody>
						</Table>

						{data.selectedTransaction?.meta?.comment ? (
							<div className="border-top-dashed border-bottom-dashed border-top border-bottom pt-3">
								{data.selectedTransaction?.meta?.comment === '' || false ? null :
									<Table responsive className="table-striped">
										<tbody>
										<tr>
											<td className="fw-semibold font-italic text-muted">{t("Comment")}:</td>
											<td className="text-muted">{data.selectedTransaction?.meta?.comment}</td>
										</tr>
										</tbody>
									</Table>
								}
							</div>
						) : null}

						{/*<div className="d-flex justify-content-end">*/}
						{/*	<Button color="primary" className="btn btn-sm" onClick={closeModal}>*/}
						{/*		Close*/}
						{/*	</Button>*/}
						{/*</div>*/}

					</div>
				) : (
					<div className="d-flex flex-column align-items-center m-4">
            <span className="d-flex align-items-center">
              <Spinner size="sm" className="flex-shrink-0"></Spinner>
              <span className="flex-grow-1 ms-2 fs-16">{t("Loading")}...</span>
            </span>
					</div>
				)
			}
		</React.Fragment>
	)
}

export default FullTransactionModal
