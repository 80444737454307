import React, {useContext, useEffect, useImperativeHandle, useState} from 'react'
import {Card, CardBody, CardHeader, Table} from "reactstrap"
import {lastTransactions} from "../api/widget"
import {ModalContext} from "../contexts"
import FullTransactionModal from "../pages/DashboardCrypto/FullTransactionModal"
import {cryptoIconsMapper, isTwoPrecision, prettyDate, toTwoPrecision} from "../helpers/utils"
import TransactionBadgeIcon from "../Components/Common/TransactionBadgeIcon"
import {useTranslation} from "react-i18next"

const LastTransactions = ({ limit, type, currencyType, methodsRef }) => {
  const [data, setData] = useState(null)
  const {openModal} = useContext(ModalContext)
  const { t } = useTranslation()

  const openTransactionHandler = (item) => {
    openModal({
      title: t('Transaction Details'),
      content: <FullTransactionModal selectedTransaction={item} />,
    });
  }

  const getTransactions = () => {
    lastTransactions({limit: limit, type: type, currencyType: currencyType}).then(r => {
      setData(r)
    })
  }

  useEffect(() => {
    getTransactions()
  }, [])

  useImperativeHandle(methodsRef, () => ({
    updateTable: () => {
      getTransactions()
    }
  }))

  return (
    <>
      <Card>
        <CardHeader>{t("Last 5 Transactions")}</CardHeader>
        <CardBody>
          <div className="table-responsive">
            <Table>
              <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">{t("Type")}</th>
                <th scope="col">{t("Created date")}</th>
                <th scope="col">{t("Base Asset")}</th>
                <th scope="col">{t("Quote Asset")}</th>
                <th scope="col">{t("Amount")}</th>
                <th scope="col">{t("USD Amount")}</th>
                <th scope="col">{t("Fee Pct")}</th>
                <th scope="col">{t("Status")}</th>
              </tr>
              </thead>
              <tbody>
              {data ? (data?.length ?
                data.map((item, index) => {
                  return (
                    <tr key={index} className="align-middle">
                      <td className="fw-medium text-primary">
                        <a className="cursor-pointer" onClick={() => openTransactionHandler(item)}>#{item.id}</a>
                      </td>
                      <td>
                        <TransactionBadgeIcon
                          transactionId={item.id}
                          transactionType={item.type}
                          invoiceId={item.invoice_id}
                          invoiceAmount={item.usd_amount}
                        />
                      </td>
                      <td>{prettyDate(item.created_at)}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-xxs me-2">
                            <img
                              src={cryptoIconsMapper[item.base_asset.currency.code]}
                              className="img-fluid"
                              alt="3"
                            />
                          </div>
                          <span>{item.base_asset.currency.code}</span>
                        </div>
                      </td>
                      <td>{item.quote_asset?.currency ?
                        <div className="d-flex align-items-center">
                          <div className="avatar-xxs me-2">
                            <img
                              src={cryptoIconsMapper[item.quote_asset.currency.code]}
                              className="img-fluid"
                              alt="3"
                            />
                          </div>
                          {item.quote_asset.currency.code}
                        </div> : '-'}
                      </td>
                      <td>{isTwoPrecision(item.base_asset.currency.code, item.base_amount)}</td>
                      <td>${toTwoPrecision(item.usd_amount)}</td>
                      <td>{(Number(item.fee_pct) * 100).toFixed(1) * 1}%</td>
                      <td>
                        <span className={'badge badge-soft-' + item.status_color}>
                          {item.status === 'pending' ? (
                            <i className="ri-time-line align-bottom me-1"></i>
                          ) : ( item.status === 'executed' ? (
                            <i className="ri-checkbox-circle-line align-bottom me-1"></i>
                            ) : ( item.status === 'declined' ?
                              <i className="ri-close-circle-line align-bottom me-1"></i> : ''
                            ))}
                          {t(`${item.status.capitalize()}`)}
                        </span>
                      </td>
                    </tr>
                  )
                }) : (<tr>
                        <td colSpan={11} className='text-center'>{t("No data")}</td>
                      </tr>))
                : (<tr>
                    <td colSpan={11} className='text-center'>{t("Loading")}...</td>
                  </tr>)
              }
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default LastTransactions
